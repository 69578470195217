<template>
  <div class="message-box">
      <div class="" style="border-bottom: 1px solid #eee;display:flex;justify-content:space-between;align-items:center;padding:10px 10px 7px 10px">
          <div class="lagrageQh-box">
              <div class="tb-title">{{$t('avatarSelect.MessageList')}}</div>
              <el-button style="outline:0;margin-left:10px;padding:5px 15px" :class="qhIndex == 0 ? 'active' : 'class-zdl'" @click="getIndex(0,'tc')" size="mini">繁體<i class="el-icon-check" v-if="qhIndex == 0"></i></el-button>
              <el-button style="outline:0;padding:5px 15px" :class="qhIndex == 1 ? 'active' : 'class-zdl'" @click="getIndex(1,'en')" size="mini">EN<i class="el-icon-check" v-if="qhIndex == 1"></i></el-button>
          </div>
          <div style="">
              <el-button @click="clickAllRead" style="outline:0;padding:5px 15px" class="active">{{$t('avatarSelect.ReadAll')}}</el-button>
          </div>
          <!-- <div>
              <div class="tb-title">{{$t('avatarSelect.MessageList')}}</div>
          </div>
          <div class="lbtn-box">
              <el-button style="outline:0" :class="qhIndex == 0 ? 'active' : 'class-zdl'" @click="getIndex(0,'tc')" size="small">繁體<i class="el-icon-check" v-if="qhIndex == 0"></i></el-button>
              <el-button style="outline:0" :class="qhIndex == 1 ? 'active' : 'class-zdl'" @click="getIndex(1,'en')" size="small">EN<i class="el-icon-check" v-if="qhIndex == 1"></i></el-button>
          </div> -->
      </div>
    <div class="mbox">
      <el-table empty-text="No Item" :data="messageList" style="width: 100%" :show-header="false">
        <el-table-column prop="create_time" label="" width="200px">
            <template slot-scope="scope">
                <div>{{scope.row.create_time}}</div>
            </template>
        </el-table-column>
        <el-table-column v-if="qhIndex == 0" prop="tc" label="">
            <template slot-scope="scope">
                <MyBadge :isShow="visibleBadge(scope.row.is_read)" :isDot="true" type="success">
                    <div @click="clickRead(scope.row)" class="cel-content" :style="scope.row.is_read == '0' ? 'font-weight:bold;' : ''">{{scope.row.tc}}</div>
                </MyBadge>
            </template>
        </el-table-column>
        <el-table-column v-if="qhIndex == 1" prop="en" label="">
            <template slot-scope="scope">
                <MyBadge :isShow="visibleBadge(scope.row.is_read)" :isDot="true" type="success">
                    <div @click="clickRead(scope.row)" class="cel-content" :style="scope.row.is_read == '0' ? 'font-weight:bold' : ''">{{scope.row.en}}</div>
                </MyBadge>    
            </template>
        </el-table-column>
        <el-table-column prop="cz" label="操作" width="300px">
            <template slot-scope="scope">
                <div v-if="scope.row.is_read == 0">
                    <el-button style="padding:5px 15px" size="mini" class="class-zdl" @click="clickRead(scope.row)">{{$t('avatarSelect.GotIt')}}</el-button>
                </div>
            </template>
        </el-table-column>
      </el-table>

    </div>
    <div class="page-box">
        <el-pagination
        background
        layout="prev, pager, next"
        :total="pageSize"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="20"
        @current-change="handerPage"
        @size-change="handleSizeChange"
        >
        </el-pagination>
        </div>
  </div>
</template>

<script>
import {getUserMessage, userMessageRead} from '../../api/http'
import MyBadge from '../../components/MyBadge.vue'
export default {
    components: {MyBadge},
    data() {
        return {
            messageList: [],
            qhIndex: 0,
            pageSize: null,
            allReadBtn: false
        }
    },
    created() {
        this.getMessageData()
    },
    watch: {
        messageList() {
            if(this.$i18n.locale == 'tw') {
                this.qhIndex = 0
            }else {
                this.qhIndex = 1
            }
        },
        getLocale(e){
                console.log('localeeee==>', e)
                if(e == 'tw') {
                    this.qhIndex = 0
                }else{
                    this.qhIndex = 1
                }
        }
    },
    computed: {
        getLocale() {
            return this.$i18n.locale
        }
    },
    methods: {
        // 顯示未讀私信
        visibleBadge(read) {
            if(read == '0') {
                return true
            }else {
                return false
            }
        },
        clickRead(item) {
            console.log('item-->', item)
            if(item.is_read == 0) {
                this.reqUserMessageRead(item.id)
            }else{
                return
            }
            
        },
        getIndex(i,lg) {
            this.qhIndex = i
        },
        handerPage(e) {
            this.getMessageData(e)
        },
        handleSizeChange(e) {
            console.log(e)
        },
        // 請求標記私信已讀
        reqUserMessageRead(id) {
            let data = {
                type: 'user_message_read',
                uid: localStorage.getItem('uid'),
                id: id
            }
            userMessageRead(data).then((res) => {
                if(res.code == 200) {
                    this.getMessageData()
                    this.$store.dispatch('getUnreadCount')
                }else {
                    this.$message.error(res.message)
                }
            })
        },
        // 点击全部已读
        clickAllRead() {
            this.reqUserMessageRead('')
        },
        // 請求全部私信列表數據
        getMessageData(page) {
            let data = {
                type: 'get_user_message',
                uid: localStorage.getItem('uid'),
                page: page || 1,
                page_size: 20,
                // is_all: 0
            }
            getUserMessage(data).then(res => {
                console.log('getusermessage==>', res)
                this.pageSize = Number(res.data.size) || 20
                this.messageList = res.data.data
            })
        }
    }
}
</script>

<style scoped>
.message-box {
  height: 100vh;
  background: #fff;
  padding-left: 2.6rem;
}
.lagrageQh-box{
    /* padding: 10px 10px 7px 10px; */
    display: flex;
    align-items: center;
    /* border-bottom: 1px solid #eee; */
}
.tb-title{
    font-size: 16px;
    font-weight: bold;
    border-left: 5px solid #00BEA4;
    padding-left: 2px;
}
.lbtn-box{
    padding-bottom: 10px;
}
.cel-content{
    cursor: pointer;
}
.class-zdl:hover{
    background: #E6F9F6;
    color: #00BEA4;
    border-color: #00BEA4;
}
.class-zdl:focus{
    background: #E6F9F6;
    color: #00BEA4;
    border-color: #00BEA4;
    outline: 0;
}
.active{
    background: #00BEA4;
    color:#fff;
}
</style>